<template>
  <q-dialog v-model="datePickerDisplay" persistent>
    <q-card class="q-pa-lg">
      <div class="q-pa-sm">
        <div class="full-width">
          <q-date
            v-model="dateSelected"
            color="primary"
            :landscape="$q.screen.gt.xs"
          />
        </div>
        <div class="row q-mt-lg">
          <q-space />
          <q-btn
            flat
            icon="clear"
            label="Cancel"
            color="primary"
            class="q-mr-sm"
            padding="xs md"
            @click="cancel"
          />
          <q-btn
            icon="date_range"
            label="Set Date"
            color="primary"
            padding="xs md"
            @click="selectDate()"
          />
        </div>
      </div>
    </q-card>
  </q-dialog>
</template>

<script>
import { date } from 'quasar';

export default {
  name: 'DatePicker',
  props: {
    originalDate: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      dateSelected: null,
      datePickerDisplay: true
    };
  },
  mounted() {
    this.setInitDates();
  },
  methods: {
    cancel() {
      this.$emit('onCancel');
    },
    selectDate() {
      const dateSelected = parseInt(date.formatDate(this.dateSelected, 'x'));
      this.$emit('onSelectDate', dateSelected);
    },
    setInitDates() {
      this.dateSelected = date.formatDate(this.originalDate, 'YYYY/MM/DD');
    }
  }
};
</script>

<style scoped></style>
